//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
//
// Breakpoints: sm 576 | md 768 | lg 992 | xl 1200 | cl 1600
// @include media-breakpoint-up(sm) { ... } i.e. min-width
// @include media-breakpoint-down(xs) { ... } i.e. max-width
// @media screen and (max-width: map-get($grid-breakpoints, "sp")) {}
// @media (min-width: 992px) {
//

/********************************************/
/*     WARM BREEZE GAS CENTRAL HEATING   		*/
/********************************************/

/********************************************************/
/* WBG - Broad Styles							                  		*/
/********************************************************/
h1,
h2,
h3,
h4 {
  font-family: $font-family-base;
  letter-spacing: 0px;
}
.container {
  max-width: 1200px;
  padding-right: 0;
  padding-left: 0;
}
.home-container {
  max-width: 1200px;
  padding-right: 40px;
  padding-left: 40px;
}

.red{color:#ed1c24}

.blue{color:#2e3192}

.grey{color:#57595a}
// .bg-footer {
// 	border-top: 7px solid #d26c1a;
// 	background: #3e3e3e;
// }
// .bg-grey {
//   background: $black;
// }

.bg-black {
  background: #000;
  // border-bottom: 3px solid #fff;
}
.bg-dark {
  background: #333333 !important;
}
.bg-grey {
  background: $grey;
}
// .bg-bluegradient {
//   background: linear-gradient(180deg, #f58323 50%, #e06919 98%);
// }
// .bg-lightgrey {
//   background: $grey !important;
// }
// .bg-bluegradient {
//   background: linear-gradient(180deg, #3bb8b3 2%, #0ba099 98%);
// }
// .bg-bluealt {
//   background: #087576;
// }

@include media-breakpoint-up(xl) {
  .home-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@include media-breakpoint-up(cl) {
  .home-container {
    padding-right: 0;
    padding-left: 0;
  }
}

/********************************************************/
/* WBH - Navbar		 // sticky-top			            			*/
/********************************************************/

// ALL NAVIGATION CONTAINERS //

// NAV BRAND //
nav#nav-brand {
  padding: 0 !important;
  // background: #fff;
  background:#FCFCFC;
  background: linear-gradient(180deg, #FCFCFC 2%, #E4E5E9 98%);
    //	padding: 0 1.5rem 0.75rem;
}
#nav-brand-container {
  // min-height: 163px;
  //	max-height: 100px;
  //	height: auto;
  padding: 20px;
}
#nav-brand-link {
  margin: 0 auto;
}
#nav-brand-link img {
  margin: 0 auto 0;
  max-height: 100px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}

#nav-mobile-action {
  background: linear-gradient(180deg, #2e3192 50%, #2454a5 95%);
  div {
    margin: 0 auto;
    padding: 0 30px;
  }
  p {
    font-family: $font-family-alt;
    margin: 0;
    text-align: center;
    color: $white !important;
    letter-spacing: 0px;
    font-size: 18px !important;
    line-height: 24px;
    font-weight: 400;
    padding: 8px 0 !important;
  }
  p a {
    color: $white !important;
    font-weight: 700;
    display: block;
  }
  p a:hover {
    // color: $black !important;
    text-decoration: underline !important;
  }
}

@media (min-width: 430px) {
  #nav-mobile-action p a {
      display: inline;
  }
}
// @include media-breakpoint-up(md) {
// }

@include media-breakpoint-up(lg) {
  #nav-brand-container {
    padding: 20px 40px;
    height: 185px;
  }
  #nav-brand-link img {
    max-height: 140px;
    padding-left: 0;
  }
}

@include media-breakpoint-up(cl) {
  #nav-brand-container {
    padding: 20px 0;
  }
}

// NAV CALL TO ACTION //
#nav-action-container {
  padding: 10px;
}
#nav-action {
  display: none;
  margin: 22px 25px 0 0;
  p {
    font-family: $font-family-base;
    font-size: 21px;
    line-height: 24px;
    color: $blue;
    // display: block !important;
    // letter-spacing: 0px;
    margin: 0;
    font-weight: 700;
    text-align: right;
  }
  p a {
    font-size: 26px;
    line-height: 28px;
    color: $red !important;
    display: block;
  }
  p span {
    display: inline-block;
    max-width: 200px;
  }
  p a:hover {
    // color: $black !important;
    text-decoration: underline !important;
  }
}

// @include media-breakpoint-up(sm) {
// }

@include media-breakpoint-up(md) {
  #nav-action {
    margin: 62px 40px 0 0;
  }
}

@include media-breakpoint-up(lg) {
  #nav-action {
    display: inline-block;
    margin: 20px 0 0;
    // p {
    //   // font-size: 20px;
    // }
  }
}

// NAV MENU //
#nav-menu {
  // border-top: 8px solid #000;
  // background: linear-gradient(180deg, #ffffff 2%, #fcf7f1 98%);
  background: $white;
}
@include media-breakpoint-up(lg) {
  #nav-menu {
    background: linear-gradient(180deg, #2e3192 50%, #2454a5 95%);
  }
}
.navbar-nav {
  margin: 20px 0 !important;
  width: 100%;
}
.navbar .nav-tabs {
  margin: 0 auto;
}
// @include media-breakpoint-up(lg) {
//   #nav-menu {
//   }
// }

/*.dropdown-arrow:after,
.dropdown-toggle:after {
  background: url("data:image/svg+xml;charset=utf8,<svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.09852 0.540851C4.29843 0.271544 4.70157 0.271544 4.90148 0.540851L7.80474 4.45198C8.04964 4.7819 7.81415 5.25 7.40326 5.25H1.59674C1.18585 5.25 0.950358 4.7819 1.19526 4.45198L4.09852 0.540851Z' fill='%23cb9418' style='transform:rotate(180deg); transform-origin:center;'/></svg>");
  opacity: 0.8;
  position: relative;
  top: 2px;
}*/

// * Full screen nav menu * //
.list-group-item {
  border: 1px solid #d0d3d6;
}
.nav-sub {
  border: 1px solid #eff3f6;
}
.nav-item,
.dropdown,
.nav-dropdown {
  color: $blue !important;
  .nav-link {
    color: $blue !important;
    font-family: $font-family-base;
    line-height: 20px;
    max-width: 280px;
  }
  .nav-link:hover {
    background-color: $red;
    color: $white !important;
  }
  .h5 {
    font-size: 14px !important;
    font-weight: 600;
    margin: 0;
    padding: 10px 0 !important;
  }
  .h6 {
    font-size: 14px !important;
    font-weight: 600;
  }
/*  .dropdown-arrow:hover:after,
  .dropdown-toggle:hover:after {
    background: url("data:image/svg+xml;charset=utf8,<svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.09852 0.540851C4.29843 0.271544 4.70157 0.271544 4.90148 0.540851L7.80474 4.45198C8.04964 4.7819 7.81415 5.25 7.40326 5.25H1.59674C1.18585 5.25 0.950358 4.7819 1.19526 4.45198L4.09852 0.540851Z' fill='%23FFFFFF' style='transform:rotate(180deg); transform-origin:center;'/></svg>");
    // opacity: 0.8;
    // position: relative;
    // top: 2px;
  }*/
  // .h5:hover {
  //   color: $blue !important;
  // }
  // .nav-sub .h6 {.
  //   padding-left: 20px;
  //   font-size: 16px;
  //   color: #666;
  // }
}

// * Dropdown icon and menu * //
.dropdown-menu-wide {
  // Mobile
  //  width: 21rem;
  width: 294px;
  .list-group-item-action {
    padding-left: 1rem !important;
  }
}

// li.dropdown > a > i {
// 	vertical-align:middle;
// 	padding: 0 0 0px;
// 	font-size: 22px;
// 	line-height: 22px;
// }

//.nav-dropdown .list-group-item-action:hover, .list-group-item-action:focus {
.dropdown .list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: $red;
  color: $white !important;
  .h6 {
    color: $white !important;
  }
}
// .dropdown .nav-sub:hover {
//   background-color: $black;
// }
/*@include media-breakpoint-up(md) {
  .dropdown-arrow:after,
  .dropdown-toggle:after {
    background: url("data:image/svg+xml;charset=utf8,<svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.09852 0.540851C4.29843 0.271544 4.70157 0.271544 4.90148 0.540851L7.80474 4.45198C8.04964 4.7819 7.81415 5.25 7.40326 5.25H1.59674C1.18585 5.25 0.950358 4.7819 1.19526 4.45198L4.09852 0.540851Z' fill='%23FFFFFF' style='transform:rotate(180deg); transform-origin:center;'/></svg>");
    // opacity: 0.8;
    // position: relative;
    // top: 2px;
  }
  .nav-dropdown {
    .dropdown-arrow:hover:after,
    .dropdown-toggle:hover:after {
      background: url("data:image/svg+xml;charset=utf8,<svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.09852 0.540851C4.29843 0.271544 4.70157 0.271544 4.90148 0.540851L7.80474 4.45198C8.04964 4.7819 7.81415 5.25 7.40326 5.25H1.59674C1.18585 5.25 0.950358 4.7819 1.19526 4.45198L4.09852 0.540851Z' fill='%23cb9418' style='transform:rotate(180deg); transform-origin:center;'/></svg>");
      // opacity: 0.8;
      // position: relative;
      // top: 2px;
    }
  }
}*/

@include media-breakpoint-up(lg) {
  .nav-item,
  .nav-dropdown,
  .dropdown {
    width: (100%/6);
    .nav-link {
      text-align: center;
      //			padding: 0.8rem 0 !important;
      max-width: auto;
    }
  }
  .navbar-nav {
    margin: 0 !important;
  }
  .nav-item,
  .dropdown,
  .nav-dropdown {
    color: $white !important;
    .nav-link {
      color: $white !important;
      max-width: 280px;
    }
    .nav-link:hover {
      // background-color: $white;
      background: linear-gradient(180deg, #b20101 50%, #ed1c24 95%);
      color: $white !important;
    }
    // .dropdown-arrow:hover:after,
    // .dropdown-toggle:hover:after {
    //   background: url("data:image/svg+xml;charset=utf8,<svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.09852 0.540851C4.29843 0.271544 4.70157 0.271544 4.90148 0.540851L7.80474 4.45198C8.04964 4.7819 7.81415 5.25 7.40326 5.25H1.59674C1.18585 5.25 0.950358 4.7819 1.19526 4.45198L4.09852 0.540851Z' fill='%23cb9418' style='transform:rotate(180deg); transform-origin:center;'/></svg>");
    //   opacity: 0.8;
    //   position: relative;
    //   top: 2px;
    // }
    // .nav-sub .h6 {
    //   padding-left: 20px;
    //   font-size: 16px;
    //   color: #666;
    // }
  }

  .nav-tabs .nav-item {
    margin-bottom: 0;
    // border-right: 1px solid #d6d4d4;
  }
  // #nav-home {
  //   border-left: 1px solid #d6d4d4;
  // }

  // .dropdown-menu-wide { // Desktop
  // 	width: 20rem;
  // }
}

// * Mobile menu * //
.navbar-toggler {
  margin: 1rem 0;
}
@include media-breakpoint-down(md) {
  .dropdown-menu-wide .list-group-item {
    padding-left: 1.5rem !important;
  }
  .nav-item .h5,
  .nav-dropdown .h5 {
    padding: 15px 20px !important;
    border-radius: 0.375rem !important;
    max-width: 294px;
  }
}

// * Mobile call button * //
// #nav-contact .nav-link {
//   margin: 10px 0;
//   font-weight: 700;
//   letter-spacing: 0.5px;
//   background: $white;
//   color: $blue !important;
// }
// #nav-contact .nav-link:hover {
//   background: $blue;
//   color: $white !important;
// }

/********************************************************/
/* WBH - Home Hero Panel                				 				*/
/********************************************************/
#border-bar {
  border-bottom: 3px solid #ffffff;
  height: 0px;
}

#hero-section {
  // max-height: 546px;
  img.bg-image {
    object-fit: cover;
  }
}
#hero-container {
  background: #f2f1ed;
  align-items: initial;
  // max-width: 1180px;
}
#hero-content {
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  h1, h2 {
    margin: 0;
    letter-spacing: 0.5px;
    // h-shadow v-shadow blur-radius color
}
  h1 {
    font-size: 2.1rem;
    font-weight: 800;
    margin-bottom: 8px;
    line-height: 2.2rem;
    color: $red !important;
    text-shadow: 0px 1px 5px #EEE;
    span {
      color: $blue !important;
    }
  }
  h2 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
    color: $white !important;
    text-shadow: 0px 2px 5px #111;
  }
}
#hero-title {
  // max-width: 600px;
  // margin: 8px auto 0;
  // padding: 0 20px 10px;
  font-family: $font-family-base;
  font-weight: 700;
  // letter-spacing: 0px;
  // span {
  //   display: block;
  // }
}

// span#ht-1 {
//   font-style: italic;
// }

@include media-breakpoint-up(sm) {
  // 576
  #hero-content {
    h1, h2 {
      margin: 0;
      line-height: 3.4rem;
    }
    h1 {
      font-size: 3.47rem;
    }
    h2 {
      font-size: 2.8rem;
    }
  }
}

@include media-breakpoint-up(md) {
  #hero-content {
    padding: 190px 0 0 0;
    // margin: 0 180px 10px 0;
  }
  #hero-title {
    text-align: center;
    padding: 0;
  }
}

@include media-breakpoint-up(lg) {
  #hero-content {
    padding: 190px 180px 0 0;
  }
}

// @include media-breakpoint-up(cl) {
// }

/*@media (min-width: 1500px) {
  #hero-section img.bg-image {
    // object-fit: contain;
  }
}*/


/********************************************************/
/* WBH - Home - Introductory title panel				*/
/********************************************************/

#home-intro-section {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
    // 	border-top: 1px solid #e6e6e6;
  // 	border-bottom: 2px solid #e6e6e6;
}
#home-intro-text {
  margin: 0px 0;
  padding: 0 10px;
  text-align: center;
  font-family: $font-family-base;
  font-weight: 400;
  h2 {
    // text-transform: uppercase;
    // max-width: 550px;
    margin: 0 auto 10px;
    color: $blue !important;
    letter-spacing: 0px;
    font-size: 1.25rem !important;
    font-weight: 700;
    line-height: 1.7rem;
  }
  p {
    margin: 0 auto 10px;
    font-size: 1.1rem !important;
    line-height: 1.4rem;
    color: $grey;
  }
}

@include media-breakpoint-up(md) {
  #home-intro-text {
    max-width: 1160px;
    h2 {
      font-size: 1.35rem !important;
    }
    p {
      max-width: 1000px;
      font-size: 1.35rem !important;
      line-height: 1.8rem;
    }
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(cl) { // 1600

/********************************************************/
/* WBH - Home - Special rollover Cards					*/
/********************************************************/

// $grey-rgb: rgb(243, 243, 243);
// $blue-rgb: rgb(32, 173, 167);
// $darkblue-rgb: rgb(22, 136, 137);

$home-card-transform: scale(1.05) rotate(0deg);

#home-cards-section {
  padding: 1.5rem 3rem 1rem;
}
#home-cards-container {
  max-width: 1200px;
  padding-right: 0;
  padding-left: 0;
  .card-body {
    padding: 1rem 0;
  }
}

// #card-container {
// 	margin-top: 0px;
// 	margin-bottom: 0px;
// }
.home-card {
  border-radius: 0px !important;
  // img {
  // 	border: 1px solid #c6c6c6;
  // }
  //	background: $grey;
  //	box-shadow:0 0 3px rgba(0,0,0,.2);
  //	width: 95%;
  //	margin: 0 auto 20px;
}
// #hc-3 a img {
// 	border: 1px solid #aaa;
// }
.mb-c {
  margin-bottom: 3rem !important;
}
.hc-content {
  // border-top: 3px solid #eeeeee;
  // border-bottom: 3px solid #f48222;
  // border-radius: 0px;
  background: $blue;

  margin: 0;
  // padding: 40px !important;
  //	text-align: center;
  h4 {
    color: $white !important;
    font-family: $font-family-base;
    font-weight: 700;
    font-size: 1.3rem;
    letter-spacing: 1px;
    margin-bottom: 6px;
  }
  p {
    color: $white !important;
    font-weight: 400;
  }
  a {
    color: $white;
    font-weight: 600;
  }
  // i {
  //   color: $white !important;
  // }
}

.hc-content:hover {
  border-radius: 0px;
  margin: 0;
  // border-bottom: 0px solid $blue;
  // background: $blue;
  // a {
  //   color: $white !important;
  // }
  //	text-align: center;
}
.box1 img,
.box1:after,
.box1:before {
  width: 100%;
  transition: all 0.3s ease 0s;
}
.box1 {
  overflow: hidden;
  position: relative;
} // box-shadow:0 0 3px rgba(0,0,0,.3)
.box1:after,
.box1:before {
  content: "";
  height: 50%;
  // background: rgba(143, 143, 143, 0.12); // Content Hover colour
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: 100% 0;
  transform: rotateZ(90deg);
}
.box1:after {
  top: auto;
  bottom: 0;
  transform-origin: 0 100%;
}
.box1:hover:after,
.box1:hover:before {
  transform: rotateZ(0);
}
.box1 img {
  height: auto;
  transform: scale(1) rotate(0);
}
.box1:hover img {
  transform: $home-card-transform;
} //filter:sepia(80%);
.box1 .box-content {
  position: absolute;
  transition: all 0.6s ease 0s;
  width: 100%;
  padding: 0 10px;
  // border-top: 3px solid $blue;
  // background: rgb(243, 243, 243); // Initial button bg
  // background: linear-gradient(180deg, #f58323 55%, #e0681a 98%);
  // background: $red;
  background: linear-gradient(180deg, #b20101 50%, #ed1c24 95%);
  bottom: -100px;
  left: 0;
  z-index: 3;
}

.box1 .box-content a {
  padding: 0;
  display: inline-block;
  height: 40px;
  width: 100%;
  transform: scale(0);
  transition: all 0.5s ease 0.2s;
  opacity: 0;
  span {
    font-family: $font-family-base;
    text-transform: uppercase;
    position: absolute;
    top: 3px;
    left: 12px;
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 40px;
    font-weight: 800;
    color: $white !important;
  }
  i.material-icons {
    position: absolute;
    top: 2px;
    right: 20px;
    font-size: 30px;
    line-height: 44px;
    color: $white;
  }
}
.box1 .box-content:hover {
  // background: rgba(22, 136, 137, 1); // Button hover bg
  // background: linear-gradient(180deg, #b20101 50%, #ed1c24 95%);
  background: $red;
  a span,
  a i.material-icons {
    color: #fff !important;
  }
}
// .box1 .box-content a:hover span {
// 	-webkit-text-stroke: 1px #FFF;
// }

.box1:hover .box-content {
  bottom: 0;
}
.box1:hover .box-content a {
  opacity: 1;
  transform: scale(1);
}

@include media-breakpoint-up(md) {
  .hc-content {
    min-height: 290px;
  }
  .hc-content h4 {
    font-size: 1.4rem;
  }
  .hc-content p {
    font-size: 0.95rem;
  }
}
// @include media-breakpoint-up(lg) {
//   /* 	.card-body {
// 		padding: 1rem 1rem 0.5rem;
// 	}
// 	.hc-content {
// 		margin: 0 0 15px;
// 	}
// }

@include media-breakpoint-up(xl) {
  .hc-content {
    min-height: 270px;
  }
  .hc-content h4 {
    font-size: 1.7rem;
  }
  .hc-content p {
    font-size: 1.1rem;
  }
}

@include media-breakpoint-up(cl) {
  #home-cards-section {
    padding: 1.5rem 0 1rem;
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(cl) { // 1600

/********************************************************/
/* WBH - Home - About Us / Our Story	              		*/
/********************************************************/
#about-section {
  padding: 0;
}


// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(cl) { // 1600

/********************************************************/
/* WBH - Home - Testimonials					              		*/
/********************************************************/
#testimonials-section {
  padding: 2rem 0 1rem;
}
#testimonials-section hr {
  border:0;
  height:6px;
  background-image:linear-gradient(to right,rgba(0,0,0,0),#e4e5e9,rgba(0,0,0,0));
  margin:30px 0;
}
#testimonials-section h4 {
  color: $grey;
  font-size: 1.4rem;
  font-weight: 700 !important;
  text-align: center;
  margin: 0;
}

#tml-quotes {
  padding: 0 0 6rem;
  // 	border-bottom: 1px solid #e6e6e6;
  //   .container {
  //     border-top: 2px solid #aaaaaa;
  //   }
}
.tml-quote {
  max-width: 540px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.4rem;
}
.tml-quotes-txt {
  padding: 40px 0 0;
  color: $red;
}
.tml-quotes-name {
  padding: 10px 0;
  color: $dark;
}
.flickity-page-dots {
  bottom: -25px;
}
.flickity-page-dots .dot {
  width: 9px;
  height: 9px;
  border-radius: 14rem;
  background: $blue;
  opacity: 0.4;
}
.flickity-page-dots .dot.is-selected {
  transform: scale(1.2);
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(cl) { // 1600

/********************************************************/
/* WBH - Footer - Logos panel							*/
/********************************************************/

#section-logos {
  padding: 2rem 0 4rem !important;
  img {
    padding: 1rem 0;
    display: block;
    margin: 0 auto;
  }
}
#section-logos-content {
  text-align: center;
}
.logo-container {
  max-width: 680px;
}
#fl-title {
  font-size: 1.1rem;
  line-height: 1.4rem;

}

@include media-breakpoint-up(md) {
  #section-logos img {
    padding: 1rem 2rem;
    display: inline;
    margin: 0;
    // padding: 0.5rem 0;
  }
}
@include media-breakpoint-up(lg) {
  #section-logos {
    padding: 1rem 0;
  }
  .logo-container {
    max-width: 800px;
  }
  #fl-title {
    font-size: 1.1rem;
    line-height: 1.4rem;
  
  }
}

@include media-breakpoint-up(xl) {
  #section-logos img {
    padding: 1rem;
  }
  .logo-container {
    max-width: 1200px;
  }
//   .logo-container {
//     max-width: 1100px;
//   }
}

// @media screen and (min-width: map-get($grid-breakpoints, "sl")) {
//   #section-logos img {
//     padding: 1rem;
//   }
// }

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(cl) { // 1600

/********************************************************/
/* WBH - Footer servicing bar          	 								*/
/********************************************************/

#section-footer-service {
  padding: 0 40px;;
  background: linear-gradient(180deg, #2454a5 5%, #2e3192 50%);
  border-bottom:5px solid #FFF;
}
#f-service {
	margin: 0 auto;
	text-align: center;
	padding: 10px 0;
	p {
    margin: 0 4px 0 0;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
		color: #FFF;
    display: block;
	}
}
p#fs-title {
	display: block;
	margin: 0 0 6px;
}
#fs-4:before {
		white-space:pre;
		content: "\A";
}

// @media screen and (max-width: 24em) {
// }

// @media screen and (min-width: 35.5em) {
// 	#f-service {
// 		padding: 10px 0px;
// 	}
// }
#fs-1:before, #fs-2:before, #fs-3:before, #fs-4:before {
  content: "";
  white-space: normal;
}
p#fs-title {
  margin: 6px 0;
}
p#fs-1, p#fs-2, p#fs-3, p#fs-4 {
  margin: 0 4px 6px 0;
  font-size: 14px;
}



@include media-breakpoint-up(sm) { // 576
	p#fs-title {
		display: inline;
		margin: 0 6px;
	}
  #f-service {
    margin: 0 auto;
    text-align: center;
    padding: 10px 0;
    p {
      margin: 0 4px 0 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #FFF;
      display: inline;
    }
  }
  
  #fs-2:before, #fs-3:before {
    content: "|\A";
  }
  #fs-4:before {
		content: "|\A";
		white-space: normal;
	}
  
}


/********************************************************/
/* WBH - Footer panel	 			                  					*/
/********************************************************/
#footer-content {
  background: linear-gradient( 180deg, #a0060d 5%, #dc0d07 95%);
}

#footer-container {
  padding: 0 20px 0 0;
}

.footer-block {
  padding: 10px 0 10px 40px;
  // padding-top: 20px;
  // padding-left: 40px;
  ul {
    margin-bottom: 0 !important;
  }
  li, p {
    // margin: 0 0 10px;
    margin: 0 0 20px;
    font-family: $font-family-base;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    // letter-spacing: 0.5px;
    color: $white;
  }
  li a {
    color: $white !important;
    font-weight: 600 !important;
  }
  li a:hover {
    color: $white !important;
  }
  li a:hover::after {
    content: " >";
  }
  p {
    margin: 0 0 5px;
  }
}

#footer-contact {
  margin: 30px 0 0;
  li p,
  li a {
    letter-spacing: 0.5px;
  }
}

#footer-brands {
  // margin: 0 auto;
  // padding: 0 0 40px 30px;
  margin: 0 0 30px;
  img {
    padding: 0;
  }
}

@include media-breakpoint-up(sm) {
  .footer-block {
    padding: 0 0 10px 40px;
    li, p {
      font-size: 20px;
      line-height: 22px;
    }
  }
}

@include media-breakpoint-up(md) {
  #footer-container {
    padding: 0 40px;
  }
  #footer-brands {
    margin: 30px 0 0;
  }  
}

@include media-breakpoint-up(lg) {
  #footer-container {
    max-width: 1160px;
  }
}
@include media-breakpoint-up(cl) {
  #footer-container {
    padding: 0;
  }
  .footer-block {
    padding-left: 0;
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(cl) { // 1600

/********************************************************/
/* WBH - Footer panel	 								*/
/********************************************************/

.text-small {
  font-size: 0.75rem !important;
}

#f-copyright-txt {
  padding-left: 40px;
  color: $grey;
  line-height: 1rem;
  a {
    color: $grey;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1rem;
  }
}

#f-copyright {
  font-size: 0.9rem;
  p,
  a {
    color: $grey;
  }
}
@include media-breakpoint-up(cl) {
  #f-copyright-txt {
    padding-left: 10px;
  }
}
